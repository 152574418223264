import React from "react";
import styles from "./HowItWorks.module.scss";
import Close from "../../components/UI/Close/Close";
import SubSection from "./SubSection/SubSection";
import GoogleLogo from "../../assets/google.png";
import Cautela from "../../assets/cautelalogo.png";
import QueridoLunes from "../../assets/queridoluneslogo.png";
import E5 from "../../assets/5logo.png";
// import feba from "../../assets/feba.svg";
// import connectingbrains from "../../assets/connectingbrains.svg";
import codeicus from "../../assets/codeicuslogo.png";
import aws from "../../assets/aws.png";
import Paisanos from "../../assets/logopaisanos.png";
import FEBA from "../../assets/febalogo.png";
import LogoPaisanosBig from "../../assets/logoPaisanosBig.png";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
const HowItWorks = ({ active, close, theme }) => {
  const { t, i18n } = useTranslation();
  return (
    <section className={`${styles.container} ${active ? styles.active : ""}`}>
      <div onClick={close} className={styles.closeContainer}>
        <Close color={"#fff"} />
      </div>
      <div className={styles.brandOwner}>{theme ? <img src={LogoPaisanosBig} alt={theme.town} /> : <img src={LogoPaisanosBig} alt='PaisanosCreando.io' />}</div>
      <h2 className={styles.title}>{t("Cómo funciona?")}</h2>
      <SubSection title='Buscar productos' description='Haciendo click en “buscar productos disponibles podés completar un formulario con los productos que buscás. Nosotros hacemos el resto y te mostramos en el mapa qué puntos de venta tienen stock.' />
      <SubSection title='Reportar disponibilidad' description='Seleccionando en el mapa un punto de venta podés informar qué productos tiene ese local disponibles para que los demás puedan saberlo.' />
      <div className={styles.helpUsToHelp}>
        <h2 className={styles.innerTitle}>{t("¡Ayudanos a ayudar!")}</h2>
        <p className={styles.description}>{t("Al ser una plataforma colaborativa, cuántos más datos carguen las personas en tiempo real, ¡más confiable y precisa va a ser! Ayudanos contando cuáles productos encontraste.")}</p>
      </div>
      <h2 className={styles.title} style={{ marginTop: 0 }}>
        {t("Colaboran")}
      </h2>
      <div className={styles.brands}>
        {/* {theme ? (
          <div className={styles.brandItem}>
            <LogoPaisanos />
          </div>
        ) : null} */}
        <div className={styles.brandItem}>
          <img src={GoogleLogo} alt='Google Logo' />
        </div>
        <div className={styles.brandItem}>
          <img src={aws} alt='Google Logo' />
        </div>
        <div className={styles.brandItem}>
          <img src={Paisanos} alt='Google Logo' />
        </div>
        <div className={styles.brandItem}>
          <img src={codeicus} alt='Google Logo' />
        </div>
      </div>
      <div className={styles.brands}>
        <div className={styles.brandItem}>
          <img src={Cautela} alt='Google Logo' />
        </div>
        <div className={styles.brandItem}>
          <img src={QueridoLunes} alt='Google Logo' />
        </div>
        <div className={styles.brandItem}>
          <img src={FEBA} alt='Google Logo' />
        </div>
        <div className={styles.brandItem}>
          <img src={E5} alt='Google Logo' />
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    theme: state.theme,
  };
};

export default connect(mapStateToProps)(HowItWorks);
