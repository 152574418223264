import * as actionTypes from "./types";
import axios from "../axios";

const promesa = new Promise((resolve, reject) => {
  setTimeout(() => {
    resolve({
      town: "España",
      language: "es",
      logoURL: "",
      theme: {
        townBar: "#92181b",
        buttons: "#FABD00",
        buttonsSec: "#fbbd00",
        background: "#92181b",
        backgroundSec: "#cf2e32",
        description: "#ffffff",
        mainText: "#AD1519",
        placeText: "#AD1519",
        highlightText: "#f7b500",
        gray: "#FFFFFF80",
      },
    });
  }, 1000);
});

export const setTheme = (i18n) => {
  return (dispatch) => {
    dispatch(showReqLoadingHandler());
    promesa.then((result) => {
      dispatch({ type: actionTypes.SET_THEME, payload: result });
      i18n.changeLanguage(result.language);
      dispatch(showReqLoadingHandler());
    });
  };
};

export const getProducts = () => (dispatch) => {
  let authToken = localStorage.getItem("token");
  let bearerToken = { headers: { "x-auth-token": authToken } };
  axios
    .get("/api/product/", bearerToken)
    .then((res) => {
      dispatch({
        type: actionTypes.GET_PRODUCTS,
        payload: res.data,
      });
    })
    .catch((err) => {});
};

export const sendProducts = (userLocation, productsArray, isFirst) => (dispatch) => {
  if (!isFirst) {
    dispatch({ type: actionTypes.SET_LOADING });
  }
  let authToken = localStorage.getItem("token");
  let bearerToken = { headers: { "x-auth-token": `${authToken}` } };
  axios({
    method: "post",
    url: "/api/sellPoint/",
    data: {
      products: productsArray,
      lat: userLocation.lat,
      lng: userLocation.lng,
    },
    ...bearerToken,
  })
    .then((res) => {
      dispatch({
        type: actionTypes.SET_SELLPOINTS,
        payload: res.data,
      });
      if (!isFirst) {
        dispatch({ type: actionTypes.SET_LOADING });
        dispatch(isSearchingHandler());
        dispatch(showSearchModalHandler());
      }
      // dispatch(getUserLocation(userLocation.lat, userLocation.lng));
    })
    .catch((err) => {
      console.log(err);
    });
};

export const sendProviderProducts = (placeId, availableProducts, notAvailableProducts) => (dispatch) => {
  let authToken = localStorage.getItem("token");
  let bearerToken = { headers: { "x-auth-token": authToken } };
  axios({
    method: "put",
    url: "/api/sp_product/",
    data: {
      availableProducts: availableProducts,
      notAvailableProducts: notAvailableProducts,
      place_id: placeId,
    },
    ...bearerToken,
  })
    .then((res) => {
      dispatch(endSearchHandler());
      dispatch(showCongratsModal());
    })
    .catch((err) => {
      console.log(err);
    });
};

export const setUserProducts = (sellPointId, avaibleProducts, notAvailableProducts) => (dispatch) => {
  let authToken = localStorage.getItem("token");
  let bearerToken = { headers: { "x-auth-token": authToken } };
  axios({
    method: "post",
    url: "/api/sp_product_user/",
    data: {
      availableProducts: avaibleProducts,
      notAvailableProducts: notAvailableProducts,
      sellPointId: sellPointId,
    },
    ...bearerToken,
  })
    .then((res) => {
      dispatch(endSearchHandler());
      dispatch(showCongratsModal());
    })
    .catch((err) => {
      console.log(err);
    });
};

export const setUser = () => (dispatch) => {
  axios
    .post("/public-api/user/")
    .then((res) => {
      localStorage.setItem("token", res.data);
    })
    .catch((err) => {});
};

export const getUserLocation = (lat, lng) => {
  return {
    type: actionTypes.SET_USER_LOCATION,
    payload: {
      lat,
      lng,
    },
  };
};

export const setPDV = (placesInfo, pdv) => {
  return {
    type: actionTypes.SET_PDV,
    payload: {
      pdv: pdv,
      placesInfo: placesInfo,
    },
  };
};

export const getLocationFromApi = () => (dispatch) => {
  axios
    .post("https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyC7tLWUWOfUmyzucUbMa73aXtuY9sqhwJ0")
    .then((res) => {
      dispatch(getUserLocation(res.data.location.lat, res.data.location.lng));
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getPlace = (placeId, pdv) => (dispatch) => {
  dispatch(showReqLoadingHandler());
  let authToken = localStorage.getItem("token");
  let bearerToken = { headers: { "x-auth-token": authToken } };
  axios
    .get(`/api/sellPoint/${placeId}`, bearerToken)
    .then((res) => {
      dispatch(setPDV(res.data, pdv));
      dispatch(showReqLoadingHandler());
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getPlaceFromSearchPDV = (placeId, fromPlaces) => (dispatch) => {
  dispatch(showReqLoadingHandler());
  let authToken = localStorage.getItem("token");
  let bearerToken = { headers: { "x-auth-token": authToken } };
  axios
    .get(`/api/sellPoint/${placeId}`, bearerToken)
    .then((res) => {
      if (fromPlaces) dispatch(showSearchPDVModalHandler());
      dispatch(showReqLoadingHandler());
      dispatch(setPDVFromSearch({ ...res.data, google_maps_id: placeId }));
    })
    .catch((err) => {
      console.log(err);
    });
};

export const showAlertModalHandler = () => {
  return {
    type: actionTypes.SHOW_ALERT_MODAL,
  };
};

export const showSearchModalHandler = () => {
  return {
    type: actionTypes.SHOW_SEARCH_MODAL,
  };
};

export const showProviderModalHandler = () => {
  return {
    type: actionTypes.SHOW_PROVIDER_MODAL,
  };
};

export const isFromSearchPDVHandler = () => {
  return {
    type: actionTypes.IS_FROM_SEARCH_PDV,
  };
};

export const isSearchingHandler = () => {
  return {
    type: actionTypes.SET_IS_SEARCHING,
  };
};

export const endSearchHandler = () => {
  return {
    type: actionTypes.END_SEARCH,
  };
};

export const closePdvHandler = () => {
  return {
    type: actionTypes.CLOSE_PDV,
  };
};

export const showCongratsModal = () => {
  return {
    type: actionTypes.SHOW_CONGRATS_MODAL,
  };
};

export const showSearchPDVModalHandler = () => {
  return {
    type: actionTypes.SHOW_SEARCH_PDV_MODAL,
  };
};

export const showReqLoadingHandler = () => {
  return {
    type: actionTypes.SHOW_REQ_LOADING,
  };
};

export const setPDVFromSearch = (pdv) => {
  return {
    type: actionTypes.SET_PDV_FROM_SEARCH,
    payload: pdv,
  };
};
