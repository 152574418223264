import React, { useEffect } from "react";
import MainPage from "./pages/MainPage/MainPage";
import logo from "./logo.svg";
import "./App.css";
import { connect } from "react-redux";
import { getUserLocation, setUser, getLocationFromApi, setTheme } from "./store/generalActions";
import Loading from "./components/UI/Loading/Loading";
import ReactGa from "react-ga";
import TagManager from "react-gtm-module";
import { useTranslation } from "react-i18next";
const tagManagerArgs = {
  gtmId: "GTM-PB6WXFX",
  auth: "yXZINSzdeA6zN",
  preview: "env-1",
};
const App = ({ getUserLocation, setUser, getLocationFromApi, showReqLoading, setTheme, theme }) => {
  const { i18n } = useTranslation();

  useEffect(() => {
    if (theme) {
      document.documentElement.style.setProperty("--townBar", theme.theme.townBar);
      document.documentElement.style.setProperty("--background", theme.theme.background);
      document.documentElement.style.setProperty("--backgroundSec", theme.theme.backgroundSec);
      document.documentElement.style.setProperty("--buttons", theme.theme.buttons);
      document.documentElement.style.setProperty("--buttonsSec", theme.theme.buttonsSec);
      document.documentElement.style.setProperty("--description", theme.theme.description);
      document.documentElement.style.setProperty("--gray", theme.theme.gray);
      document.documentElement.style.setProperty("--mainText", theme.theme.mainText);
      document.documentElement.style.setProperty("--highlightText", theme.theme.highlightText);
      var link = document.querySelector("link[rel*='icon']") || document.createElement("link");
      link.type = "image/x-icon";
      link.rel = "shortcut icon";
      link.href = theme.logoURL;
      document.getElementsByTagName("head")[0].appendChild(link);
    }
  }, [theme]);

  useEffect(() => {
    const subdomainPeru = window.location.host.includes("peru");
    if (subdomainPeru) {
      setTheme(i18n);
    }
    setTheme(i18n);
    TagManager.initialize(tagManagerArgs);
    ReactGa.initialize("UA-160261815-2");
    ReactGa.pageview(window.location.pathname + window.location.search);
    const hasToken = localStorage.getItem("token");
    if (!hasToken) setUser();
    navigator.geolocation.getCurrentPosition(
      (position) => {
        getUserLocation(position.coords.latitude, position.coords.longitude);
      },
      () => {
        getLocationFromApi();
      },
      {
        enableHighAccuracy: true,
        timeout: 5000,
      }
    );
    if (!navigator.geolocation) {
      getLocationFromApi();
    }
  }, []);

  return (
    <div className='App'>
      {showReqLoading ? <Loading /> : null}
      <MainPage />
    </div>
  );
};

const MapStateToProps = (state) => {
  return {
    showReqLoading: state.showReqLoading,
    theme: state.theme,
  };
};

export default connect(MapStateToProps, {
  getUserLocation,
  setUser,
  getLocationFromApi,
  setTheme,
})(App);
